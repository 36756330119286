import {CookieManager} from '../modules/cookie.js';

/**
 * The cookie generated here is consumed by GTM (Google Tag Manager) to track user consent.
 * @see https://github.com/InteractionDesignFoundation/gtm-cookie-consent-value
 */

/**
 * @typedef {'all' | 'customize'} consentType
 */

/**
 * @typedef {object} PolicyRevision
 * @property {number} timestamp
 * @property {string} revision
 * @property {{[key: string]: boolean}} consents
 */

class ToggleButton {
    #isOpen = false;

    /**
     * @param {HTMLElement} toggleButtonElement
     * @param {ConsentBanner} consentBanner
     */
    constructor(toggleButtonElement, consentBanner) {
        this.toggleButtonElement = toggleButtonElement;
        this.consentBanner = consentBanner;

        if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
            // Use click for touch devices to avoid 300ms delay
            this.toggleButtonElement.addEventListener('touchstart', () => this.toggle());
        } else {
            this.toggleButtonElement.addEventListener('mouseover', () => this.open());
        }
    }

    toggle() {
        if (! this.#isOpen) {
            this.open();
        } else {
            this.close();
        }
    }

    open() {
        this.#isOpen = true;
        this.consentBanner.setAttribute('aria-expanded', this.#isOpen);
        this.toggleButtonElement.classList.add('consentBanner__button--hidden');
    }

    close() {
        this.#isOpen = false;
        this.consentBanner.setAttribute('aria-expanded', this.#isOpen);
        this.consentBanner.addEventListener('transitionend', () => {
            this.toggleButtonElement.classList.remove('consentBanner__button--hidden');
        }, {once: true});
    }
}

class ConsentForm {
    #repository = new ConsentRepository();
    /**
     * @param {HTMLFormElement} formElement
     * @param {ConsentBanner} consentBanner
     */
    constructor(formElement, consentBanner) {
        this.formElement = formElement;
        this.consentBanner = consentBanner;

        let policyRevision = this.#repository.retrieve();
        this.policyRevisionExists = policyRevision !== null;
        if (policyRevision === null) {
            policyRevision = {
                timestamp: Date.now(),
                revision: this.consentBanner.currentRevision,
                consents: {},
            };
        }

        this.formElement.addEventListener('submit', event => this.onSubmit(event));

        this.settingsPanelElement = formElement.querySelector('#consentBanner__settingsPanel');

        const htmlForm = new HtmlForm(policyRevision.consents);
        this.policyRevision = {
            ...policyRevision,
            consents: htmlForm.values,
        };

        formElement.querySelectorAll('.consentBanner__cookieConsent input')
            .forEach(input => htmlForm.bind(input.name, input));

        const consentTypeForm = new HtmlForm({
            consentType: this.isCustomized() ? 'customize' : 'all',
        });
        const inputs = formElement.querySelectorAll('input[type=radio][name="consentType"]');
        consentTypeForm.bind('consentType', inputs);
        inputs.forEach(input => input.addEventListener('change', (event) => this.#onInputChange(event)));

        this.consentTypeFormInputs = consentTypeForm.values;

        this.init();
    }

    init() {
        if (this.consentTypeFormInputs.consentType === 'customize') {
            this.formElement.setAttribute('aria-expanded', 'true');
            this.settingsPanelElement.setAttribute('aria-hidden', 'false');

            this.consentBanner.isBeingCustomized(true);
        }
    }

    /** @param {SubmitEvent} submitEvent */
    onSubmit(submitEvent) {
        submitEvent.preventDefault();

        this.persist();

        this.consentBanner.onConsentGranted();
    }

    #onInputChange() {
        const isBeingCustomized = this.consentTypeFormInputs.consentType === 'customize';

        this.settingsPanelElement.setAttribute('aria-hidden', isBeingCustomized ? 'false' : 'true');
        this.formElement.setAttribute('aria-expanded', this.consentTypeFormInputs.consentType === 'customize' ? 'true' : 'false');

        this.consentBanner.isBeingCustomized(isBeingCustomized);

        if (! isBeingCustomized) {
            this.grantAll();
        }
    }

    /**
     * @param {string} revision
     * @returns {boolean} */
    isConsentGrantedFor(revision) {
        return this.policyRevisionExists && this.policyRevision.revision === revision;
    }

    /** @returns {boolean} */
    isCustomized() {
        if (! this.policyRevisionExists) {
            return false;
        }

        for(const key in this.policyRevision.consents) {
            if (this.policyRevision.consents[key] === false) {
                return true;
            }
        }
    }

    grantAll() {
        this.consentBanner.consentNames.forEach(consent => this.policyRevision.consents[consent] = true);
    }

    persist() {
        this.#repository.store({...this.policyRevision});
    }
}

class CloseButton {
    /**
     * @param {HTMLButtonElement} closeButtonElement
     * @param {ConsentBanner} consentBanner
     */
    constructor(closeButtonElement, consentBanner) {
        this.closeButtonElement = closeButtonElement;
        this.closeButtonElement.addEventListener('click', () => consentBanner.toggleButton.close());
    }
}

class ConsentRepository {
    #cookieName = 'consent';

    /** @returns {PolicyRevision | null} */
    retrieve() {
        try {
            const cookieValue = CookieManager.read(this.#cookieName);

            if (! cookieValue) {
                return null;
            }

            return JSON.parse(decodeURIComponent(cookieValue));
        } catch (error) {
            return null;
        }
    }

    /** @param {PolicyRevision} consent */
    store(consent) {
        CookieManager.write(this.#cookieName, encodeURIComponent(JSON.stringify(consent)));
    }
}

class ConsentBanner extends HTMLElement {
    #initialized = false;

    constructor() {
        super();

        if (! this.#initialized) {
            this.currentRevision = this.dataset.currentRevision;
            /** @type {Array<string>} */
            this.consentNames = JSON.parse(this.dataset.consents);

            const toggleButton = this.querySelector('.consentBanner__button');
            this.toggleButton = new ToggleButton(toggleButton, this);

            const closeButton = this.querySelector('.consentBanner__closeButton');
            this.closeButton = new CloseButton(closeButton, this);

            const form = this.querySelector('.consentBanner__form');
            this.form = new ConsentForm(form, this);
            const _this = this;

            this.initiateConsent();

            document.addEventListener('click', (event) => {
                const isClickOutside = !_this.contains(event.target);

                if (isClickOutside) {
                    _this.closeButton.closeButtonElement.click();
                }
            });

            this.#initialized = true;
        }
    }

    onConsentGranted() {
        window.location.reload();
    }

    initiateConsent() {
        if (this.form.isConsentGrantedFor(this.dataset.currentRevision)) {
            return;
        }

        this.toggleButton.open();

        setTimeout(() => {
            this.toggleButton.close();
            this.form.grantAll();
            this.form.persist();
        }, 2000);
    }

    /** @param {boolean} isCustomized */
    isBeingCustomized(isCustomized) {
        if (isCustomized) {
            this.classList.add('consentBanner--customized');
        } else {
            this.classList.remove('consentBanner--customized');
        }
    }
}

class HtmlForm {
    /** @type {{[key: string]: HTMLInputElement | HTMLInputElement[]}} */
    #bindings = {};

    /** @param {{[key: string]: boolean | string}} initialValues */
    constructor(initialValues = {}) {
        this.values = new Proxy(initialValues, this.#handler());
    }

    /**
     * @private
     */
    #handler() {
        const self = this;

        return {
            set(target, property, value) {
                const success = Reflect.set(target, property, value);
                if (success) {
                    self.#updateDOM(property, value);
                }
                return success;
            },
        };
    }

    /**
     * @param {string} property
     * @param {boolean | string} value
     * @private
     */
    #updateDOM(property, value) {
        if (Object.prototype.hasOwnProperty.call(this.#bindings, property)) {
            return;
        }

        const inputOrGroup = this.#bindings[property];
        if (inputOrGroup instanceof NodeList) { // Radio buttons
            inputOrGroup.forEach((radio) => {
                radio.checked = radio.value === value;
            });
        } else { // Other input types
            if (inputOrGroup.type === 'checkbox') {
                inputOrGroup.checked = value;
            } else {
                inputOrGroup.value = value;
            }
        }
    }

    /**
     * @param {string} key
     * @param {HTMLInputElement | HTMLInputElement[]} inputOrGroup
     */
    bind(key, inputOrGroup) {
        // Initialize proxy object to match current input state
        this.#bindings[key] = inputOrGroup;

        const updateValue = (input) => {
            this.values[key] = input.type === 'checkbox' ? input.checked : input.value;
        };

        if (inputOrGroup instanceof NodeList) {
            inputOrGroup.forEach((input) => {
                input.addEventListener('change', () => updateValue(input));
            });
        } else {
            const eventType = inputOrGroup.type === 'checkbox' ? 'change' : 'input';
            inputOrGroup.addEventListener(eventType, () => updateValue(inputOrGroup));
        }

        // Update DOM
        this.#updateDOM(key, this.values[key]);
    }
}


customElements.define('consent-banner', ConsentBanner);
